@import '@pushpress/shared-components/build/styles/config.scss';

.root {
  display: flex;
  min-height: 100%;
  @media (min-width: $breakpoint-lg) {
    max-width: 1280px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  padding: 40px;
}

.nav {
  display: none;
  flex: 0 0 232px;
  padding: 24px 16px;
  @media (min-width: $breakpoint-sm) {
    display: block;
  }
}

.content {
  flex: 1;
  width: 100%;
  @media (min-width: $breakpoint-sm) {
    width: auto;
    padding-bottom: 40px;
    border-left: 1px solid #d9d9d9;
  }
}

.tabs {
  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}

// TODO understand why the shared components css have a bigger specificity
.text {
  padding: 24px !important;
  @media (min-width: $breakpoint-sm) {
    padding: 32px !important;
  }
}

.plans {
  padding: 24px;
  @media (min-width: $breakpoint-sm) {
    padding: 0 32px;
  }
}
