@import '@pushpress/shared-components/build/styles/config.scss';

$header-height: 96px;
$header-height-lg: 112px;

.root {
  position: relative;
  height: 100%;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: $header-height;
  padding: 0 24px;
  background: $pacific-primary-600;
  @media (min-width: $breakpoint-md) {
    height: $header-height-lg;
    padding: 0 32px;
  }
}

.body {
  position: relative;
  @media (min-width: $breakpoint-md) {
    height: calc(100% - $header-height-lg);
  }
}

.body-container {
  height: 100%;
  padding-bottom: 20px;
}
