@import '@pushpress/shared-components/build/styles/config.scss';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  &.dark {
    .title {
      color: $white;
    }
  }
  &.light {
    .title {
      color: $wolf-800;
    }
  }
  &.margin-bottom-40 {
    margin-bottom: $grid-unit-40;
  }
  &.margin-bottom-80 {
    margin-bottom: $grid-unit-80;
  }
  .box {
    width: $grid-unit-60;
    height: $grid-unit-60;
    margin-right: $grid-unit-30;
    background: $white;
    border-radius: 10px;
  }
  .logo {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    object-fit: cover;
    object-position: center;
    background: transparent;
  }
  .title {
    @include heading-4;
  }
}
