@import '@pushpress/shared-components/build/styles/config.scss';

.root {
  position: relative;
}

.list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item,
.itemSelected {
  display: flex;
  align-items: center;
  min-height: 44px;
  padding: 8px 16px;
  font-family: Lato;
  font-size: 16px;
  line-height: 20px;
  color: $wolf-800;
  border-radius: 6px;
}

.item {
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    background: $wolf-100;
  }
}

.itemSelected {
  background: $pacific-primary-100;
  color: $pacific-primary-600;
  border: 1px solid $pacific-primary-100;
}

.root {
  &.horizontal {
    overflow: auto;
    padding: 0 24px;
    &::-webkit-scrollbar {
      display: none;
      height: 0;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      &:hover {
        background: $wolf-200;
      }
    }
    .list {
      flex-direction: row;
      gap: 8px;
      width: max-content;
    }
    .item,
    .itemSelected {
      white-space: nowrap;
    }
    .item {
      border: 1px solid $wolf-300;
    }
  }
  &.vertical {
    .list {
      flex-direction: column;
      gap: 12px;
    }
  }
}
