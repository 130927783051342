@import '@pushpress/shared-components/build/styles/config.scss';

.plan-card {
  transition: all 500ms ease-out;
  cursor: pointer;
  box-shadow: -0.5px 0.5px 1px 0.5px #0000000a;

  &:hover {
    border-color: $pacific-primary-600;
  }

  .plan-title {
    overflow: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 8px;
  }

  .dot {
    font-size: 6px;
  }
}
